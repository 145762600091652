<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" :disabled="loading" @click="saveBanner('bannerForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-loading="loading">
      <el-col :span="20" :offset="2">
          <custom-header :title="'Сурталчилгаа засварлах'"/>
        <el-form label-position="top" :model="bannerForm" :rules="bannerRules" ref="bannerForm">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Баннер код" prop="banner_code">
                        <el-input v-model="bannerForm.banner_code" placeholder="Админы жагсаалт дээр харагдах нэр, код ..."></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Монгол гарчиг">
                        <el-input v-model="bannerForm.title" placeholder="Сурталчилгааны монгол гарчиг ..."></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Англи гарчиг">
                        <el-input v-model="bannerForm.title_eng" placeholder="Сурталчилгааны англи гарчиг..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8" class="text-center">
                      <el-form-item label="Сурталчилгаа байрлах бүтээгдэхүүн">
                        <el-switch
                          disabled
                          v-model="bannerForm.channel"
                          active-text="Апп"
                          inactive-text="Веб"
                          active-value="MOBILE"
                          inactive-value="WEB"
                          >
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Монгол тайлбар">
                        <el-input v-model="bannerForm.description" placeholder="Ток ток аппын сурталчилгаа ..." class="block">
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                       <el-form-item label="Англи тайлбар">
                        <el-input v-model="bannerForm.description_eng" placeholder="Ток ток app ..." class="block">
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгаа харуулах хуудас">
                        <el-select v-model="bannerForm.page" placeholder="Бүтээгдэхүүн ..." class="block" @change="onChangePage">
                          <el-option
                            v-for="(page, index) in bannerForm.channel === 'WEB' ? bannerPage : appPage"
                            :key="index"
                            :label="page.label"
                            :value="page.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-switch
                        v-if="bannerForm.channel === 'WEB' && bannerForm.page === 'category' && categoryAll"
                        v-model="categoryAll"
                        active-text="Бүгд"
                        inactive-text="Сонгох"
                        active-value='1'
                        inactive-value='0'
                        >
                      </el-switch>
                    </el-col>
                    <el-col :span="12" class="mt20">
                  <div v-if="bannerForm.page === 'product' && bannerForm.channel === 'WEB' || bannerForm.page === 'product' && bannerForm.channel === 'MOBILE' || bannerForm.page === 'category' && bannerForm.channel === 'MOBILE'">
                    <div>
                      <el-checkbox v-if="products.length <= 0" class="mt15" v-model="allProducts" true-label="all" false-label="">Бүх категори</el-checkbox>
                      <tree-selector v-if="allProducts !== 'all'" v-model="bannerForm.multi_id" :treeData="categories" multiple width="100%" />
                    </div>
                  </div>
                  <div v-if="this.bannerForm.page === 'brand'">
                      <div>
                        <el-checkbox v-if="brands.length <= 0" class="mt15" v-model="allBrands" true-label="all" false-label="">Бүх брэнд</el-checkbox>
                        <el-button class="ml120" v-if="allBrands !== 'all'" icon="el-icon-search" @click="visibleBrandDialog = true">Брэнд сонгох</el-button>
                      </div>
                      <div v-if="brands.length > 0">
                        <div v-for="(item, index) in brands" :key="index">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="20">
                              {{item.brand_monName}}
                            </el-col>
                            <el-col :span="4">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="removeBrand(item)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="this.bannerForm.page === 'category'  && categoryAll !== '1' && bannerForm.channel === 'WEB'" class="mt5">
                      <el-input
                        placeholder="Эрэгтэй, эмэгтэй, гутал ..."
                        v-model="treeFilterText"
                      >
                        <el-button slot="prepend" icon="el-icon-search"></el-button>
                      </el-input>
                      <el-tree
                        :data="categories"
                        node-key="uniq_id"
                        :props="treeDefaultProps"
                        :filter-node-method="filterNode"
                        :default-checked-keys="multiIds"
                        show-checkbox
                        ref="tree"
                        class="mt20">
                      </el-tree>
                    </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгааны байрлал" prop="position">
                        <el-select placeholder="Сурталчилгааны байрлал сонгох" class="block" v-model="bannerForm.position" @change="onChangeSize">
                          <el-option
                            v-for="(type, index) in bannerPositionType"
                            :key="index"
                            :label="type.label"
                            :value="type.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгааны төрөл" prop="type">
                        <el-select placeholder="Сурталчилгааны төрлөө сонгоно уу" class="block" v-model="bannerForm.type" @change="onChangeType(bannerForm.type)">
                           <el-option
                              v-for="(type, index) in bannerForm.channel === 'WEB' ? bannerType : bannerMobileType"
                              :key="index"
                              :label="type.label"
                              :value="type.value">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mt20" v-if="bannerForm.channel === 'MOBILE' && bannerForm.type === 'CATEGORY'">
                      <div>
                        <el-tree :data="categories" :props="treeDefaultProps" @node-click="handleNodeClick"></el-tree>
                      </div>
                    </el-col>
                    <el-col :span="12" class="mt25">
                      <div v-if="bannerForm.channel === 'MOBILE' && bannerForm.type === 'BRAND'">
                        <!-- <el-checkbox  class="mt15" v-model="allBrands" true-label="all" false-label="">Бүх брэнд</el-checkbox> -->
                        <el-button class="ml120" v-if="allBrands !== 'all'" icon="el-icon-search" @click="visibleBrandDialog = true">Брэнд сонгох</el-button>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <div v-if="brands.length > 0 && bannerForm.channel === 'MOBILE' && bannerForm.type === 'BRAND'">
                        <div v-for="brand in brands" :key="brand.brand_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="4">
                              <el-image :src="brand.brand_logo[0]"></el-image>
                            </el-col>
                            <el-col :span="16">
                              <h3>{{brand.name_mon}}</h3>
                            </el-col>
                            <el-col :span="4">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="removeBrand(brand)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Шилжих веб хуудас url:" v-if="bannerForm.type === 'WEB_LINKING'">
                        <el-input v-model="web_url" placeholder="https://www.toktokmall.mn/"></el-input>
                      </el-form-item>
                      <div v-else>
                          <div>
                            <el-checkbox v-if="productsApp.length <= 0 && bannerForm.channel === 'WEB' && bannerForm.type === 'WEB_LINKING'" class="mt15" v-model="allProducts" true-label="all" false-label="">Бүх бүтээгдэхүүн</el-checkbox>
                            <el-button v-if="bannerForm.channel === 'MOBILE' && bannerForm.type === 'PRODUCT'"
                              class="ml40 mt25"
                              icon="el-icon-search"
                              @click="generateButton('status')"
                            >Шилжих бүтээгдэхүүн сонгох</el-button>
                          </div>
                          <div v-if="productsApp.length > 0 && bannerForm.type === 'PRODUCT'">
                            <div v-for="product in productsApp" :key="product.productId">
                                <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                                  <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                    <div class="image-holder">
                                      <img :src="product.image[0]" alt="">
                                    </div>
                                  </el-col>
                                  <el-col :xl="16" :lg="16" :md="14" :sm="14" :xs="14">
                                    <div class="text-color-secondary">{{product.name_mon}}</div>
                                    <!-- <div>{{variant.variant_name}}</div> -->
                                    <!-- <div class="text-color-placeholder">{{variant.variant_sku}}</div> -->
                                  </el-col>
                                  <el-col :xl="4" :lg="4" :md="4" :sm="4" :xs="4">
                                    <div class="text-right">
                                      <i class="el-icon-close cursor-pointer" @click="removeProduct('productsApp', product)"></i>
                                    </div>
                                  </el-col>
                                </el-row>
                              </div>
                          </div>
                        </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="Эхлэх огноо" prop="start_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="bannerForm.start_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Дуусах огноо" prop="end_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="bannerForm.end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Зураг" prop="image_url">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :on-preview="handlePictureCardPreview"
                          :file-list="tempImagesMon"
                          :on-remove="(file) => handleRemove(file, 'mon')"
                          :auto-upload="false"
                          :on-change="(file) => getFile(file, 'mon', this.width, this.height)">
                          <span v-if="this.width !== null">{{this.width}}x{{this.height}}</span>
                          <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Зураг англи">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :file-list="tempImagesEng"
                          :on-preview="handlePictureCardPreview"
                          :on-remove="(file) => handleRemove(file, 'eng')"
                          :auto-upload="false"
                          :on-change="(file) => getFile(file, 'eng', width, height)">
                          <span v-if="this.width !== null">{{this.width}}x{{this.height}}</span>
                          <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                   <el-row :gutter="20">
                     <el-col class="text-center">
                      <el-form-item label="Сурталчилгааны төлөв" prop="status">
                        <el-switch
                          style="display: block"
                          v-model="bannerForm.status"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Идэвхтэй"
                          inactive-text="Идэвхгүй"
                          active-value='active'
                          inactive-value='expired'>
                        </el-switch>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="12">
                          <el-form-item label="Гарчгийн өнгө">
                            <el-select
                                  v-model="bannerForm.title_color"
                                  filterable
                                  class="block">
                                  <el-option
                                    v-for="(color, index) in colorList"
                                    :key="index"
                                    :label="color.name_mon"
                                    :value="color.hex_code">
                                  </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      <el-col :span="12">
                      <el-form-item label="Жишээ">
                        <span :style="`color: ${ bannerForm.title_color }; background-color: gray; display: block; text-align: center; border-color: gray; border-width: thin; border-style: solid; border-radius: 5px`"><strong>Гарчгийн сонгосон өнгө</strong></span>
                      </el-form-item>
                    </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item label="SortNumber">
                    <el-input v-model="bannerForm.sortNumber"></el-input>
                   </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-button type="danger" @click="confirmBannerDialog = true">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" :disabled="loading" @click="saveBanner('bannerForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <brand-search-dialog v-if="visibleBrandDialog" :visible-brand-dialog.sync="visibleBrandDialog" :brands.sync="brands"></brand-search-dialog>
        <product-search-dialog v-if="visibleProductDialog" :visible-product-dialog.sync="visibleProductDialog" :products.sync="products" :products-app.sync="productsApp" :type.sync="typeButton"></product-search-dialog>
      </el-col>
    </el-row>
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link :to="'/banner-list?' + 'page=' + this.$route.query.page + '&size=' + this.$route.query.size + '&search=' + this.$route.query.search + '&tab=' + this.$route.query.tab + '&status=' + this.$route.query.status">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <el-dialog
      title="Сурталчилгаа устгах"
      :visible.sync="confirmBannerDialog"
      width="50%"
    >
      <div>
        <strong>{{bannerForm.title}}</strong> сурталчилгааг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmBannerDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button :disabled="loading" type="danger" @click="deleteBanner(bannerForm)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible" title="Сурталчилгааны зураг">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import loader from '../../../components/common/loader'
import { handlers } from '../../../helpers/custom'
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader.vue'
import BrandSearchDialog from '../discount/components/brandSearchDialog'
import ProductSearchDialog from './components/bannerSearchProductDialog'
import TreeSelector from '../../../components/common/treeSelector'
const bannerPositions = [{
  value: 'left-top',
  label: 'Зүүн дээр'
}, {
  value: 'right-top',
  label: 'Баруун дээр'
}, {
  value: 'right-bottom',
  label: 'Баруун доор'
}, {
  value: 'top',
  label: 'Дээр'
}, {
  value: 'middle-right',
  label: 'Баруун дунд'
}, {
  value: 'middle-left',
  label: 'Зүүн дунд'
}, {
  value: 'middle',
  label: 'Дунд'
}, {
  value: 'bottom',
  label: 'Доор'
}]

export default {
  name: 'bannerEdit',
  components: {
    customHeader,
    loader,
    BrandSearchDialog,
    ProductSearchDialog,
    TreeSelector
  },

  data () {
    return {
      selectCategory: null,
      sort: [],
      typeButton: '',
      dialogImageUrl: '',
      dialogVisible: false,
      allProducts: '',
      allBrands: '',
      categoryAll: '1',
      width: null,
      height: null,
      colorList: [],
      tempImagesMon: [],
      tempImagesEng: [],
      confirmBannerDialog: false,
      visibleBrandDialog: false,
      visibleProductDialog: false,
      isUploading: false,
      loading: false,
      confirmLeaveForm: false,
      treeFilterText: '',
      treeDefaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      pop_up_image_url: '',
      multiIds: [],
      pop_up_description: '',
      tempMonImages: [],
      tempEngImages: [],
      categories: [],
      brands: [],
      products: [],
      productsApp: [],
      web_url: '',
      bannerForm: {
        channel: 'web',
        banner_code: '',
        title_color: '',
        brand_id: '',
        product_id: '',
        description: '',
        multi_id: [],
        description_eng: '',
        sortNumber: '',
        status: '',
        image_url: '',
        image_url_eng: '',
        page: '',
        type: '',
        pass_prop: [],
        position: '',
        title: '',
        title_eng: '',
        start_date: new Date(),
        end_date: new Date()
      },

      bannerPage: [{
        value: 'home',
        label: 'Нүүр хуудас'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }, {
        value: 'brands',
        label: 'Брэндүүд'
      }, {
        value: 'brand',
        label: 'Брэнд'
      }, {
        value: 'category',
        label: 'Категори'
      }, {
        value: 'special',
        label: 'Онцлох'
      }, {
        value: 'coupon',
        label: 'Купон'
      }, {
        value: 'co_working',
        label: 'Хамтран ажиллах'
      }, {
        value: 'new',
        label: 'Шинэ'
      }, {
        value: 'sale',
        label: 'Хямдрал'
      }, {
        value: 'mongolian-brand',
        label: 'Монгол брэнд'
      }, {
        value: 'finish',
        label: 'Finish хуудас'
      }],

      appPage: [{
        value: 'home',
        label: 'Нүүр хуудас'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }, {
        value: 'category',
        label: 'Категори'
      }],

      bannerPositionType: bannerPositions,

      bannerType: [{
        value: 'IMAGE',
        label: 'Зураг'
      }, {
        value: 'WEB_LINKING',
        label: 'Веб холбоостой'
      }],

      bannerMobileType: [{
        value: 'PRODUCT',
        label: 'Бүтээгдэхүүн сонгох'
      }, {
        value: 'BRAND',
        label: 'Брэнд сонгох'
      }, {
        value: 'CATEGORY',
        label: 'Категори сонгох'
      }],

      bannerRules: {
        banner_code: [
          { required: true, message: 'Сурталчилгааны кодоо оруулна уу', trigger: 'blur' }
        ],
        title_eng: [
          { required: true, message: 'Сурталчилгааны англи гарчиг оруулна уу', trigger: 'blur' }
        ],
        position: [
          { required: true, message: 'Сурталчилгааны байрлал сонгоно уу', trigger: 'blur' }
        ],
        type: [
          { required: true, message: 'Сурталчилгааны төрөл сонгоно уу', trigger: 'blur' }
        ],
        start_date: [
          { required: true, message: 'Эхлэх огноогоо сонгоно уу', trigger: 'blur' }
        ],
        end_date: [
          { required: true, message: 'Дуусах огноогоо сонгоно уу', trigger: 'blur' }
        ],
        image_url: [
          { required: true, message: 'Зураг оруулна уу', trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Төлөв сонгоно уу', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'Тайлбар оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  created () {
    if (this.$route.params.id) {
      this.getOneBanner(this.$route.params.id)
    }
    this.getCategories()
    this.getColors()
  },

  methods: {
    handleNodeClick (data) {
      this.selectCategory = data
    },
    getCategories () {
      services.getCategoriesAsTree().then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
      })
    },
    getProductData (productId) {
      this.isLoading = true
      services.getOneProduct(productId).then((response) => {
        if (response.status === 'success' && response.data.length !== 0) {
          this.productsApp = response.data
        }
      })
    },
    generateButton (type) {
      this.typeButton = type
      this.visibleProductDialog = true
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },

    onChangeType (data) {
      if (data === 'IMAGE') {
        this.web_url = ''
      }
    },

    getColors () {
      services.getColors('').then(data => {
        this.colorList = data.datas
      })
    },

    onChangeSize (position, page = this.bannerForm.page) {
      if (page === 'home' && position === 'left-top') {
        this.width = 840
        this.height = 560
      } else if (page === 'home' && position === 'right-top' ? page === 'home' && position === 'right-top' : page === 'home' && position === 'right-bottom') {
        this.width = 400
        this.height = 260
      } else if (page === 'product' && position === 'right-top' ? page === 'product' && position === 'right-top' : page === 'product' && position === 'right-bottom') {
        this.width = 250
        this.height = 250
      } else if (page === 'finish' && position === 'right-top' ? page === 'finish' && position === 'right-top' : page === 'finish' && position === 'right-bottom') {
        this.width = 250
        this.height = 250
      } else if (page === 'brand' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'category' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'category' && position === 'middle-right' ? page === 'category' && position === 'middle-right' : page === 'category' && position === 'middle-left') {
        this.width = 500
        this.height = 400
      } else if (page === 'special' && position === 'left-top') {
        this.width = 840
        this.height = 560
      } else if (page === 'special' && position === 'right-top' ? page === 'special' && position === 'right-top' : page === 'special' && position === 'right-bottom') {
        this.width = 400
        this.height = 260
      } else if (page === 'coupon' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'co_working' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'co_working' && position === 'middle') {
        this.width = 600
        this.height = 400
      } else if (page === 'new' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'new' && position === 'middle') {
        this.width = 600
        this.height = 400
      } else if (page === 'sale' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'sale' && position === 'middle') {
        this.width = 600
        this.height = 400
      } else if (page === 'mongolian-brand' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'mongolian-brand' && position === 'middle') {
        this.width = 600
        this.height = 400
      }
    },

    onChangePage (page) {
      if (page === 'home' && this.bannerForm.channel === 'WEB') {
        const tempData = bannerPositions.slice(0, 3)
        this.bannerPositionType = tempData
      } else if (page === 'product' && this.bannerForm.channel === 'WEB') {
        const tempData = bannerPositions.slice(1, 3)
        this.bannerPositionType = tempData
      } else if (page === 'brand') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'category') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'special') {
        const tempData = bannerPositions.slice(0, 3)
        this.bannerPositionType = tempData
      } else if (page === 'coupon') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'co_working') {
        const tempData = [bannerPositions[3], bannerPositions[6]]
        this.bannerPositionType = tempData
      } else if (page === 'new') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'sale') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'mongolian-brand') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'finish') {
        const tempData = bannerPositions.slice(1, 3)
        this.bannerPositionType = tempData
      } else if (page === 'home' && this.bannerForm.channel === 'MOBILE') {
        const tempData = [bannerPositions[3], bannerPositions[7]]
        this.bannerPositionType = tempData
      } else if (page === 'product' && this.bannerForm.channel === 'MOBILE') {
        const tempData = bannerPositions.slice(4, 6)
        this.bannerPositionType = tempData
      }
    },

    getOneBanner (id) {
      this.isLoading = true
      services.getOneBanner(id).then(response => {
        if (response.status === 'success') {
          this.multiIds = response?.data?.multi_id.length > 0 ? response.data.multi_id : []
          this.bannerForm = response.data
          this.setImages(this.bannerForm.image_url)
          this.setImagesEng(this.bannerForm.image_url_eng)
          if (response.data && response.data.pass_prop) {
            response.data.pass_prop.forEach(el => {
              this.web_url = el.web_url
              this.pop_up_image_url = el.pop_up_image_url
              this.pop_up_description = el.pop_up_description
            })
          }
          if (response.data.multi_id[0] === 'all' && response.data.multi_id.length > 0 && response.data.multi_id !== null) {
            this.categoryAll = '1'
            this.allProducts = response.data.multi_id[0]
          } else {
            this.categoryAll = '0'
          }
          if (response.data.page) {
            this.onChangePage(response.data.page)
          }
          if (response.data.product_id === 'all' && response.data.multi_id[0] === 'all' && response.data.channel === 'WEB') {
            this.allProducts = response.data.product_id
          }
          if (response.data.brand_id === 'all' && response.data.channel === 'WEB') {
            this.allBrands = response.data.brand_id
          } else if (response.data.channel === 'MOBILE' && response.data.type === 'BRAND') {
            response.data.pass_prop[0].data.filters.forEach(brand => {
              this.brands.push(brand)
            })
          } else if (response.data.channel === 'MOBILE' && response.data.type === 'BRAND' && response.data.brandName !== '') {
            this.brands = [{
              brand_monName: response.data.brandName
            }]
          } else if (response.data.channel === 'MOBILE' && response.data.type === 'PRODUCT') {
            this.getProductData(response.data.pass_prop[0].product_id)
          }
          if (response.data.status === 1) {
            this.bannerForm.status = 'active'
          } else {
            this.bannerForm.status = 'expired'
          }
          this.onChangeSize(response.data.position, response.data.page)
          this.isLoading = false
        }
        this.onChangePage(response.data.page)
      })
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'mon') {
        this.tempImagesMon.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesMon.splice(tempId, 1)
      } else if (type === 'eng') {
        this.tempImagesEng.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesEng.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    setImages (image) {
      if (image !== '') {
        this.tempImagesMon.push({
          url: image
        })
      }
    },

    setImagesEng (image) {
      if (image !== '') {
        this.tempImagesEng.push({
          url: image
        })
      }
    },

    getFile (file, type, width, height) {
      handlers.checkImageSize(file, true, width, height).then(() => {
        if (type === 'mon') {
          this.isUploading = true
        } else if (type === 'eng') {
          this.isUploadingEng = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'mon') {
              this.isUploading = false
            } else if (type === 'eng') {
              this.isUploadingEng = false
            }
            if (response.status === 'success') {
              if (type === 'mon') {
                this.tempImagesMon.push({
                  url: response.url
                })
              } else if (type === 'eng') {
                this.tempImagesEng.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    removeBrand (val) {
      this.brands = this.brands.filter(brand => brand !== val)
      this.bannerForm.brand_id = ''
    },

    removeProduct (type, val) {
      if (type === 'products') {
        this.products = this.products.filter(product => product !== val)
        this.bannerForm.product_id = ''
      } else {
        this.productsApp = this.productsApp.filter(product => product !== val)
      }
    },

    filterNode (value, data) {
      if (!value) return true
      return data.name_mon.indexOf(value) !== -1
    },

    saveBanner (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          var tempFormData = JSON.parse(JSON.stringify(this.bannerForm))
          this.loading = true
          if (tempFormData.page === 'home' || tempFormData.page === 'special' || tempFormData.page === 'coupon' || tempFormData.page === 'co_working' || tempFormData.page === 'new' || tempFormData.page === 'sale' || tempFormData.page === 'mongolian-brand') {
            tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
          } else if (tempFormData.page === 'product') {
            if (tempFormData.product_id === '' && this.allProducts !== '') {
              tempFormData.multi_id = []
              tempFormData.multi_id.push(this.allProducts)
              tempFormData.product_id = 'all'
            } else {
              const multiId = [...tempFormData.multi_id.filter(id => id !== 'all')]
              tempFormData.multi_id = multiId
              tempFormData.product_id = ''
            }
            tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
          } else if (tempFormData.page === 'brand') {
            if (tempFormData.brand_id === '' && this.brands.length === 0) {
              tempFormData.brand_id = this.allBrands
            } else {
              this.brands.forEach(brand => {
                tempFormData.brand_id = brand.brand_id
              })
            }
            tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
          } else if (tempFormData.page === 'category' && tempFormData.channel === 'WEB') {
            tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
            if (this.categoryAll === '1') {
              tempFormData.multi_id = []
              tempFormData.multi_id.push('all')
            } else {
              const checkedNodes = this.$refs.tree.getCheckedNodes()
              tempFormData.multi_id = []
              checkedNodes.forEach(category => {
                tempFormData.multi_id.push(category.uniq_id)
              })
            }
          } else if (tempFormData.page === 'category' && tempFormData.channel === 'MOBILE' && this.allProducts === 'all') {
            tempFormData.multi_id = []
            tempFormData.multi_id.push('all')
          } else if (tempFormData.page === 'category' && tempFormData.channel === 'MOBILE' && tempFormData.multi_id.length > 0 && this.allProducts === '') {
            tempFormData.multi_id = tempFormData.multi_id.filter(el => el !== 'all')
          }
          if (this.tempImagesMon.length > 0) {
            this.tempImagesMon.forEach(element => {
              tempFormData.image_url = element.url
            })
          } else {
            tempFormData.image_url = ''
          }
          if (this.tempImagesEng.length > 0) {
            this.tempImagesEng.forEach(element => {
              tempFormData.image_url_eng = element.url
            })
          } else {
            tempFormData.image_url_eng = ''
          }
          if (tempFormData.channel === 'WEB') {
            tempFormData.pass_prop = []
            tempFormData.pass_prop.push(
              {
                web_url: this.web_url,
                pop_up_image_url: this.pop_up_image_url,
                pop_up_description: this.pop_up_description
              }
            )
          } else {
            this.productsApp.forEach(product => {
              tempFormData.pass_prop = []
              tempFormData.pass_prop.push(
                {
                  web_url: this.web_url,
                  pop_up_image_url: this.pop_up_image_url,
                  pop_up_description: this.pop_up_description,
                  product_id: product.productId
                }
              )
            })
          }
          if (this.bannerForm.status === 'active') {
            tempFormData.status = 1
          } else {
            tempFormData.status = 0
          }
        }
        // Mobile App banner
        if (tempFormData.channel === 'MOBILE' && tempFormData.type === 'BRAND') {
          tempFormData.pass_prop = []
          const tempArray = []
          this.brands.forEach(brand => {
            tempArray.push({
              key: Object.prototype.hasOwnProperty.call(brand, 'brand_id') ? brand.brand_id : brand.key,
              brand_logo: brand.brand_logo,
              name_mon: Object.prototype.hasOwnProperty.call(brand, 'brand_monName') ? brand.brand_monName : brand.name_mon,
              name_eng: Object.prototype.hasOwnProperty.call(brand, 'brand_engName') ? brand.brand_monName : brand.name_eng
            })
          })
          tempFormData.pass_prop.push({
            channel: 'toktokmall',
            data: {
              filters: tempArray,
              name_eng: 'Brand',
              name_mon: 'Бренд'
            }
          })
        } else if (tempFormData.channel === 'MOBILE' && tempFormData.type === 'CATEGORY' && this.selectCategory !== null) {
          tempFormData.pass_prop = []
          tempFormData.pass_prop.push({
            channel: 'toktokmall',
            data: {
              filters: [
                {
                  key: this.selectCategory.uniq_id,
                  name_eng: this.selectCategory.name_eng,
                  name_mon: this.selectCategory.name_mon
                }
              ],
              name_eng: 'Category',
              name_mon: 'Ангилал'
            }
          })
        }
        services.updateBanner(tempFormData).then(response => {
          if (response.status === 'success') {
            this.alertReporter('Амжилттай', response.message, 'success')
            this.loading = false
            this.$router.push({ name: 'bannerList' })
          } else if (response.status === 'unsuccess') {
            this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
            this.loading = false
          }
        })
      })
    },

    deleteBanner (body) {
      this.loading = true
      services.deleteBanner({
        id: body.id
      }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${body.id} дугаартай сурталчилгаа устлаа`, 'success')
          this.loading = false
          this.$router.push({
            name: 'bannerList'
          })
        } else if (response.status === 'unsuccess') {
          this.alertReporter('Алдаа', `${body.id} дугаартай сурталчилгаа устгахад алдаа гарлаа`, 'error')
          this.loading = false
        }
      })
    }
  }
}
</script>

<style>

</style>
