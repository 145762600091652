<template>
  <!-- Category search -->
  <el-dialog
    title="Бүтээгдэхүүн сонгох"
    :visible.sync="show"
    @close="closeDialog"
    width="600px"
  >
    <div>
      <el-input
        placeholder="Ундаа, ус ..."
        v-model="filterText"
        @input="getProducts()"
      >
        <el-button slot="prepend" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div class="mt20" v-loading="loading">
      <div v-for="(product, index) in products" :key="index" class="border-top pt10 mb10">
        <el-row :gutter="20">
          <el-col :span="20">
            {{product.name_mon}}
          </el-col>
          <el-col :span="4">
            <div class="text-right">
              <el-checkbox v-model="product.checked"></el-checkbox>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-pagination
      class="text-right mt10"
      background
      :page-size="pageSize"
      :current-page.sync="currentPage"
      :page-sizes="[5, 20, 50, 100]"
      layout="sizes, prev, pager, next"
      @size-change="sizeChangeHandler"
      @current-change="curentPageHandler"
      :total="totalCount">
    </el-pagination>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">Cancel</el-button>
      <el-button type="success" @click="returnProducts">
        Бүтээгдэхүүн сонгох
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import services from '../../../../helpers/services'
export default {
  name: 'bannerSearchProductDialog',
  props: [
    'visibleProductDialog',
    'type'
  ],
  data () {
    return {
      filterText: '',
      loading: false,
      products: [],
      show: this.visibleProductDialog,
      pageSize: 20,
      totalCount: 0,
      field: '',
      sort: '',
      activeTabName: 'all',
      currentPage: 1
    }
  },
  methods: {
    sizeChangeHandler (item) {
      this.pageSize = item
      this.getProducts()
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.getProducts()
    },
    getProducts () {
      const body = {
        supplier: [],
        brand: []
      }
      const query = '?search_text=' + this.filterText + '&from=' + this.pageSize * (this.currentPage - 1) + '&size=' + this.pageSize + '&category_id=' + this.field + '&sort=' + this.sort + '&status=' + 'active'
      this.loading = true
      services.getAllProducts(body, query).then(response => {
        if (response.datas && response.status === 'success') {
          response.datas.forEach(product => {
            const property = {
              checked: false
            }
            Object.assign(product, property)
          })
          this.products = response.datas
          this.loading = false
        } else {
          this.loading = false
        }
      })
    },
    closeDialog () {
      this.$emit('update:visibleProductDialog', false)
    },
    returnProducts () {
      const tempProducts = [...this.products.filter(product => product.checked)]
      if (tempProducts.length > 0) {
        if (this.type === 'status') {
          this.$emit('update:productsApp', tempProducts)
          this.show = false
        } else {
          this.$emit('update:products', tempProducts)
          this.show = false
        }
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүнээ сонгоно уу',
          duration: 2000
        })
      }
    }
  }
}
</script>
